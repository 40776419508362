import { Center } from "@@panda/jsx";
import { Typography } from "components/atoms/Typography";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export function ErrorScreen({ children }: Props) {
  return (
    <Center height="100%">
      <Typography variant="heading.2">{children}</Typography>
    </Center>
  );
}
